<template>
  <div>
    <div class="top-tap">
      <div class="tap-con" @click="changePage(0)" :class="num === 0 ? 'tap-active' : ''">
        <div class="tap-font">科普教学</div>
      </div>
      <div class="tap-con" @click="changePage(1)" :class="num === 1 ? 'tap-active' : ''">
        <div class="tap-font">策略训练</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
    };
  },
  mounted() {
    this.num = 0;
  },
  methods: {
    changePage(n) {
      this.num = n;
      this.$emit('changeNum',n)
    },
  },
};
</script>

<style scoped lang="less">
.top-tap {
  width: 100%;
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  display: flex;
  // justify-content: space-around;
  border-bottom: 3px solid #f4f6f5;
  box-sizing: border-box;
  .tap-con {
    cursor:pointer;
    width: 140px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tap-img {
      padding: 15px 0;
    }
    .tap-font {
      padding: 10px 0;
      font-size: 22px;
      color: #999999;
      position: relative;
    }
  }
  .tap-active {
    .tap-font {
      color: #3573ec;
    }
  }
  .tap-active::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 50px;
    display: block;
    height: 4px;
    border-radius: 2px;
    background-color: #3573ec;
  }
}
</style>