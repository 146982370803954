<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="banner">
      <img :src="require('@/assets/images/kp.jpg')" alt="" />
    </div>
    <div class="wzcon">
      <div class="wztit">{{ opImgList.tit }}</div>
      <div class="wzw" v-html="opImgList.wzcon"></div>
    </div>
    <div></div>
    <video-tap @changeNum="changeNum"></video-tap>
    <div>
      <router-view />
    </div>
    <floor></floor>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header.vue";
import videoTap from "@/components/videoTap.vue";
import Floor from "@/components/Floor.vue";
// import PolyvPlayer from '@/components/polyv.vue';
export default {
  components: {
    HeaderNav,
    Floor,
    videoTap,
    // PolyvPlayer
  },
  data() {
    return {
      options: 0,
      wznr: {
        clcon: {
          tit: "策略训练",
          wzcon:
            '蓝目量化，策略训练，软件使用，点滴荟萃。如何把自己从庞杂的金融信息筛选中解放出来?是困扰无数交易者的难题。蓝目数字团队研发的"蓝目训练器” 量化分析工具， 可以帮助不懂算法的普通交易者轻松解决这个难题。下面就跟着我们使用教程，一起来进行策略训练吧。',
        },
        kpcon: {
          tit: "科普教学",
          wzcon:
            "<p>量化交易是什么?</p> 量化交易只有数学家和程序员才能做吗?普通人是否望尘莫及?人工智能如何让庞杂的数据分析筛选变得简单?自动化交易如何让交易买卖变得更加及时(批量买入、算法条件卖出) ?未来的投资决策能否让人工智能来完成?带着这些问题和使命感，蓝目数字团队制作了-系列科普教学片，来解除一个一个的疑惑。",
        },
      },
    };
  },
  mounted() {},
  computed: {
    opImgList: function () {
      let value;
      if (this.options === 1) {
        value = this.wznr.clcon;
      } else if (this.options === 0) {
        value = this.wznr.kpcon;
      }
      return value;
    },
  },
  methods: {
    changeNum(n) {
      this.options = n;
      if (n === 0) {
        this.$router.push("/kpcon");
      } else if (n === 1) {
        this.$router.push("/clcon");
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  min-width: 1000px;
  margin: 0;
  padding: 0;
  img {
    width: 100%;
  }
}
.wzcon {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0;
  .wztit {
    font-size: 23px;
    padding-bottom: 50px;
  }
  .wzw {
    font-size: 16px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
  }
}
.img-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-row-gap: 82px;
  grid-column-gap: 5%;
  padding: 65px 0;
  box-sizing: border-box;
}
</style>
